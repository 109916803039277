<template>
    <div>
      <section id="snippet-1" class="wrapper bg-light wrapper-border">
        <div class="container pt-15 pt-md-17 pb-13 pb-md-15">
          <div class="row mb-3">
            <div class="col-md-10 col-lg-12 col-xl-10 col-xxl-9 mx-auto text-center">
              <!-- <h2 class="fs-15 text-uppercase text-muted mb-3">Our Team</h2> -->
              <h3 class="display-4 mb-7 px-lg-19 px-xl-18">Data Pegawai Outsourcing</h3>
            </div>
            <!--/column -->
          </div>
          <!--/.row -->
          <div class="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0">
            <div class="col-md-6 col-lg-4" v-for="(data) in list_data" :key="data.id">
              <div class="position-relative">
                <div class="shape rounded bg-soft-blue rellax d-md-block" data-rellax-speed="0" style="bottom: -0.75rem; right: -0.75rem; width: 98%; height: 98%; z-index:0"></div>
                <br>
                <div class="card">
                  <figure class="card-img-top"><img style="max-width: 100%; height: 350px !important;" :src="file_path+data.foto" alt="" /></figure>
                  <div class="card-body px-6 py-5">
                    <h4 class="mb-1">{{ data.nama }}</h4>
                    <p class="mb-0">{{ data.jabatan }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div> <br><br>
          <div class="text-center mt-50">
                <!-- <a href="#" class="btn btn-primary btn-sm rounded-pill">Small Button</a> -->
                <button @click="btn_prev" type="button" class="btn btn-primary btn-sm rounded-pill" id="pref">
                  <i class="uil uil-arrow-left"></i>
                </button>
                &nbsp;
                <span style="font-size: 85%; color: #a5a5a5">
                    <b>{{page_first}} - {{page_last}}</b>
                </span>
                &nbsp;
                <button @click="btn_next" type="button" class="btn btn-primary btn-sm rounded-pill" id="next">
                  <i class="uil uil-arrow-right"></i>
                </button>
            </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>

const ADD_URL = URL_APP + "api/v1/publishDpmptspDataPegawai/";
const FILE_LOAD = URL_APP + "uploads/";


export default {

    data() {
        return {
            data_load : false,
            form : {
                id : '',
                nama : '',
                jabatan : '',
                isi: "",
                judul : '',
                sumber : '',
                deskripsi : '',
                uraian : '',
                foto : '',
                unit_kerja : '',
                status : '',
                keterangan : '',
            },
            id :'',
            file_path: FILE_LOAD,

            list_data: [],
            page_first: 1,
            page_last: 0,
            cari_value: "",
            kategori: "Non ASN (OUTSOURCING)",
        }
    },
    methods: {
    getView: function() {
      fetch(ADD_URL + "view", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          data_ke: this.page_first,
          cari_value: this.cari_value,
          kategori: this.kategori,
        })
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_data = res_data.data;
            this.page_last = res_data.jml_data;
        });
    },

    selectData: function(data) {
        this.form = {
            id : data.id,
            nama : data.nama,
            jabatan : data.jabatan,
            isi : data.isi,
            foto : data.foto,
        };
    },

    mdl_exit: function() {
      this.judul = "";
      this.uraian = "";
      this.file = "";
      this.file_old = "";
    },

    //   ====================== MODAL ======================

        buka_modal: function(modalku) {
            var modal = document.getElementById(modalku);
            modal.style.display = "block";
        },

        tutup_modal: function(modalku) {
            var modal = document.getElementById(modalku);
            modal.style.display = "none";
    },


    //   ====================== MODAL ======================
    // ================== PAGINASI ====================
    alertku: function(type, title) {
      this.$swal({
        type: type,
        title: title,
        showConfirmButton: false,
        timer: 800
      });
    },

    btn_prev : function(){
        if(this.page_first>1){
            this.page_first--
        }else{
            this.page_first = 1;
        }
        this.getView();
    },

    btn_next : function(){
        
        if(this.page_first >= this.page_last){
            this.page_first == this.page_last
        }else{
            this.page_first++;
        }
        this.getView();
    },

    cari_data : function(){

        this.page_first = 1;
        this.getView();
    },
  // ================== PAGINASI ====================
  },
  // =============================================================== METHODS =========================================================================

  mounted() {

        this.getView();

    }
};
</script>
